@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  body {
    background-color: mat.m2-get-color-from-palette($background, dialog) !important;
  }

  .secondary-toolbar-text {
    color: mat.m2-get-color-from-palette($primary);
  }

  .countdown-timer {
    color: mat.m2-get-color-from-palette($primary);
  }

  .review-toggle-buttons {

    .mat-button-toggle {
      color: mat.m2-get-color-from-palette($primary);

      &.mat-button-toggle-checked {
        color: mat.m2-get-color-from-palette($primary, A100);
        background: mat.m2-get-color-from-palette($primary);
      }

      &.mat-button-toggle-disabled {
        color: mat.m2-get-color-from-palette($foreground, disabled-text);
        background: mat.m2-get-color-from-palette($background, dialog);

        &.mat-button-toggle-checked {
          color: white;
          background: mat.m2-get-color-from-palette($accent);
        }
      }
    }
  }

  .control-button {
    color: mat.m2-get-color-from-palette($primary);
  }

  .no-product-image {
    color: mat.m2-get-color-from-palette($accent);
  }

  .viewport-thumbnail-selected {
    background: mat.m2-get-color-from-palette($primary);
  }

  .comparison-div {
    background: mat.m2-get-color-from-palette($foreground, dividers);
  }

  .price-correction-fieldset {
    background: mat.m2-get-color-from-palette($foreground, dividers);
  }

  .insight-review {
    background: mat.m2-get-color-from-palette($background, dialog);
  }

  .viewport-info {
    background-color: mat.m2-get-color-from-palette($foreground, dividers);
  }
}
